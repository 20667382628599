<template>
  <div class="dashboard mt-3">
    <div class="m-container">
      <template v-if="driver">
        <template v-if="driver.verificationStatus === $options.verificationStatuses.pending">
          <v-alert type="info">
            {{ $t('pendingProfile') }}
          </v-alert>
        </template>
        <template v-if="driver.verificationStatus === $options.verificationStatuses.decline">
          <v-alert type="error">
            {{ $t('declinedProfile') }}
          </v-alert>
        </template>
        <template v-if="driver.verificationStatus === $options.verificationStatuses.approve">
          <div class="mb-2 subheader">
            <div class="subheader-row">
              <div v-if="myProfile" class="d-flex justify-start align-center subheader-left">
                <v-img :src="myProfile.avatarLink" max-width="160"></v-img>
                <div class="ml-3">
                  <h3>
                    {{ myProfile.firstName }} {{ myProfile.lastName }}
                  </h3>
                  <div class="d-flex justify-start align-center mt-3">
                    <v-icon color="secondary">verified</v-icon>
                    <span class="ml-1">
                      {{ $t('verifyAccount') }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="subheader-right">
                <div class="statistic-item">
                  <div class="statistic-item__icon info">
                    <v-icon color="white" large>delivery_dining</v-icon>
                  </div>
                  <div class="statistic-item__text">
                    <p>
                      {{ $t('successDeliveries') }}
                    </p>
                    <strong>
                      145
                    </strong>
                  </div>
                </div>
                <div class="statistic-item">
                  <div class="statistic-item__icon secondary">
                    <v-icon color="white" large>star</v-icon>
                  </div>
                  <div class="statistic-item__text">
                    <p>
                      {{ $t('satisfactionRating') }}
                    </p>
                    <strong>
                      9.5
                    </strong>
                  </div>
                </div>
                <div class="statistic-item">
                    <div class="statistic-item__icon warning">
                      <v-icon color="white" large>account_balance_wallet</v-icon>
                    </div>
                    <div class="statistic-item__text">
                      <p>
                        {{ $t('yourBalance') }}
                      </p>
                      <strong>
                        $ 356.30
                      </strong>
                    </div>
                  </div>
              </div>
            </div>
          </div>
          <v-row>
            <v-col cols="12" md="2" class="hide-mobile">
              <v-card
                class="mx-auto elevation-0"
                max-width="300"
                tile
              >
                <v-list shaped nav>
                  <v-subheader>{{ $t('menu') }}</v-subheader>
                  <v-list-item-group
                    color="primary"
                  >
                    <v-list-item
                      v-for="(item, i) in menuList"
                      :key="i"
                      v-show="item.show"
                      :class="{ 'v-list-item--active': item.to === activeRouteName }"
                      @click="navigateTo(item)"
                    >
                      <v-list-item-icon class="mr-4">
                        <v-icon v-text="item.icon"></v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title v-text="item.text"></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-col>
            <v-col cols="12" md="10" class="pt-4 pt-sm-7">
              <router-view/>
            </v-col>
          </v-row>
        </template>
      </template>
      <template v-if="!driver && !driverLoading">
        <div class="dashboard-register">
          <div class="dashboard-register__hint text-center mt-10">
            {{ $t('notRegisteredUserDriverHint') }}
          </div>
          <div class="d-flex justify-center align-center mt-5">
            <v-btn
              :to="$localize({ name: 'registration' })"
              color="secondary"
            >
              {{ $t('register') }}
            </v-btn>
          </div>
        </div>
      </template>
      <template v-if="!driver && driverLoading">
        <div class="dashboard-pending">
          <v-progress-circular
            :size="60"
            :width="5"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import {verificationStatuses} from "@/helpers/variables/statuses";
import {menuList} from '@/helpers/variables'
import {clearTokens} from "@/helpers/tokens";

export default {
  verificationStatuses,
  data() {
    return {
      activeRouteName: ''
    }
  },
  computed: {
    ...mapGetters({
      myProfile: 'auth/myProfile',
      driver: 'driver/currentItem',
      driverLoading: 'driver/loading',
    }),
    menuList,
  },
  mounted() {
    this.fetchDriver()
    this.activeRouteName = this.$route.name
  },
  methods: {
    ...mapActions({
      fetchDriver: 'driver/fetchItem',
    }),
    handleLogout() {
      clearTokens()
      this.$router.push({name: "auth"})
      setTimeout(() => {
        window.location.reload()
      }, 200)
    },
    navigateTo(item) {
      if (item.to === 'logout') {
        this.handleLogout()
        return
      }
      this.$router.push({name: item.to})
      this.activeRouteName = item.to

    }
  }
}
</script>
<style lang="scss">
.statistic {
  &-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 30px;
    &__icon {
      width: 60px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #000;
      border-radius: 20px;
      @include respondTo(1100px){
        width: 50px;
        height: 50px;
      }
    }

    &__text {
      padding-left: 10px;

      strong {
        font-size: 20px;
      }

      p {
        margin-bottom: 5px;
        font-weight: 500;
        font-size: 14px;
        color: $silver;
      }
    }
  }
}
.subheader{
  @include respondTo(960px){
    display: none;
  }
  &-row{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &-left{
    @include respondTo(1100px){
      .v-image{
        max-width: 100px!important;
      }
    }
  }
  &-right{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.dashboard {
  padding-bottom: 40px;

  &-pending {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 25vh;

    &__label {
      padding: 20px 40px;
      border-radius: 5px;
      background: $green-light;
      color: $white;
      font-weight: 500;
    }
  }

  &-register {
    &__hint {
      font-weight: 500;
    }
  }
}
</style>
